
import { Component, Prop, VModel, Vue, Watch } from 'vue-property-decorator';
import api from '@/modules/core/core-api';
import Offer from '@/models/Offer';
import { ActivityLog } from '@/types';
import OpEventsHistoryCard from '@/components/Op/EventsHistory/OpEventsHistoryCard.vue';
import OpEventsHistoryTable from '@/components/Op/EventsHistory/OpEventsHistoryTable.vue';

@Component({ components: { OpEventsHistoryCard, OpEventsHistoryTable } })
export default class OpEventsHistory extends Vue {
  @Prop({ default: () => '' }) title!: string;
  @Prop() offer!: Offer | null;
  @VModel() show!: boolean;

  private activityLogs: ActivityLog[] = [];
  private loading = false;

  private async loadActivity(): Promise<ActivityLog[]> {
    if (this.offer) {
      const result = await api.get(`/offers/${this.offer.uuid}/activity`);
      return result.data;
    }

    return [];
  }

  @Watch('offer', { deep: true })
  private async onOfferChange() {
    this.activityLogs = [];
    this.loading = true;
    this.activityLogs = await this.loadActivity();
    this.loading = false;
  }
}

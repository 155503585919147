
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';
import { isNumber, isString } from 'lodash';
import moment from 'moment';
import colors from 'vuetify/lib/util/colors';
import i18n from '@/plugins/i18n';
import {
  ActionTypes as OffersActionTypes,
  GetterTypes as OffersGetterTypes,
  MutationTypes as OffersMutationTypes,
  OfferResponseData,
  ScheduleOfferPayload
} from '@/store/offers/types';
import PwrVue from '@/components/PwrVue';
import Offer from '@/models/Offer';
import {
  ActionsLoading,
  DataTableOfferItem,
  ProcessOfferActions,
  TableHeader
} from '@/views/AdminPanel/AdminOffers/types';
import { GetterTypes } from '@/store/types';
import { Dictionary } from '@/types';
import { RoutesNames as OfferRoutesNames } from '@/router/offers/types';
import { UserBaseModel } from '@/modules/core/core-types';
import { PermissionsHelper } from '@/modules/core/core-auth';
import { PwrSnackbarTypes } from '@/components/Pwr/PwrSnackbar/types';
import PwrCard from '@/components/Pwr/PwrCard/PwrCard.vue';
import PwrCardTitle from '@/components/Pwr/PwrCard/PwrCardTitle.vue';
import PublicJobOffers from '@/views/Offers/Offers/PublicJobOffers.vue';
import OpSplitLayout from '@/views/Layouts/OpSplitLayout.vue';
import OfferFilters from '@/views/Offers/OfferFilters/OfferFilters.vue';
import PwrBtn from '@/components/Pwr/Buttons/PwrBtn/PwrBtn.vue';
import OpSearchInput from '@/components/Op/OpSearchInput.vue';
import ActionItem from '@/views/AdminPanel/AdminOffers/Components/ActionItem.vue';
import AdminPanelActionItemsGroup from '@/views/AdminPanel/AdminOffers/Components/ActionItemGroup.vue';
import PwrYesNoDialog from '@/components/Pwr/Dialogs/PwrYesNoDialog.vue';
import OpPagination from '@/components/Op/OpPagination.vue';
import OpScheduleConfirmation from '@/components/Op/Offers/OpScheduleConfirmation.vue';
import NewOfferDialog from '@/views/AdminPanel/AdminOffers/Components/NewOfferDialog.vue';
import PwrSnackbar from '@/components/Pwr/PwrSnackbar/PwrSnackbar.vue';
import CompetitionConclusionDialog from '@/views/AdminPanel/AdminOffers/Components/CompetitionConclusionDialog.vue';
import OffersView from '@/views/Offers/OffersView.vue';
import OpMoreInfo from '@/components/Op/Offers/OpMoreInfo.vue';
import OpEventsHistoryCard from '@/components/Op/EventsHistory/OpEventsHistoryCard.vue';
import OpEventsHistoryTable from '@/components/Op/EventsHistory/OpEventsHistoryTable.vue';
import OpEventsHistory from '@/components/Op/EventsHistory/OpEventsHistory.vue';


const offersNamespace = 'offers';

@Component({
  components: {
    OpEventsHistory,
    OpEventsHistoryTable,
    OpEventsHistoryCard,
    CompetitionConclusionDialog,
    PwrSnackbar,
    NewOfferDialog,
    OpScheduleConfirmation,
    PwrYesNoDialog,
    AdminPanelActionItemsGroup,
    AdminPanelActionItem: ActionItem,
    OpSearchInput,
    PwrCardTitle,
    PwrCard,
    OffersView,
    PublicJobOffers,
    OpSplitLayout,
    OfferFilters,
    PwrBtn,
    OpPagination,
    OpMoreInfo
  },
  metaInfo: {
    title: (i18n.t('views.adminPanel.jobOffers.title') as string) || 'Administracja Ofert Pracy'
  }
})
export default class AdminOffers extends PwrVue {
  // Root vuex
  @Getter(GetterTypes.GET_ENABLE_SCROLL_UP_FAB) isScrollUpFabEnabled!: boolean;

  // Offers vuex
  @Action(OffersActionTypes.FETCH_OFFERS, { namespace: offersNamespace }) fetchOffers!: (
    force: boolean
  ) => void;

  @Action(OffersActionTypes.CLEAR_SELECTED_OFFERS, { namespace: offersNamespace })
  clearSelectedOffers!: () => void;

  @Action(OffersActionTypes.CLEAR_LAST_ACTION_RESPONSE, { namespace: offersNamespace })
  clearLastActionResponse!: () => void;

  @Action(OffersActionTypes.DELETE_OFFER, { namespace: offersNamespace }) deleteOffer!: (
    offer: Offer
  ) => Promise<boolean>;

  @Action(OffersActionTypes.PUBLISH_OFFER, { namespace: offersNamespace }) publishOffer!: (
    offer: Offer
  ) => Promise<boolean>;

  @Action(OffersActionTypes.SCHEDULE_OFFER, { namespace: offersNamespace }) scheduleOffer!: (
    payload: ScheduleOfferPayload
  ) => Promise<boolean>;

  @Action(OffersActionTypes.ARCHIVE_OFFER, { namespace: offersNamespace }) archiveOffer!: (
    offer: Offer
  ) => Promise<boolean>;

  @Getter(OffersGetterTypes.GET_PROCESSED_BY_FILTERS, { namespace: offersNamespace })
  offers!: Offer[];

  @Mutation(OffersMutationTypes.SET_OFFERS, { namespace: offersNamespace })
  setOffers!: Offer[];

  @Getter(OffersGetterTypes.GET_SELECTED_OFFERS, { namespace: offersNamespace })
  selectedOffers!: Offer[];

  @Getter(OffersGetterTypes.GET_LAST_ACTION_RESPONSE_CODE, { namespace: offersNamespace })
  lastActionResponseCode!: number | undefined;

  @Getter(OffersGetterTypes.GET_LAST_ACTION_RESPONSE_DATA, { namespace: offersNamespace })
  lastActionResponseData!: string | OfferResponseData | undefined;

  @Watch('isDesktop')
  private onIsDesktopChange(): void {
    if (this.isDesktop) {
      this.enableScrollUpFab();
    } else {
      this.disableScrollUpFab();
    }
  }

  private async mounted(): Promise<void> {
    this.desktopBreakpoints = ['lg', 'xl'];
    this.onIsDesktopChange();
    this.resetAfkTimer();
    await this.fetchOffers(true);
    this.checkPermissions(this.user);
  }

  /**
   * Activity log
   */

  private activityLogDialog = false;
  private activityLogOffer: Offer | null = null;

  private showActivityLogButton = false;

  private showActivitylogDialog(offerUuid: string): void {
    const offer = this.getOfferByUuid(offerUuid);

    if (offer) {
      this.activityLogOffer = offer;
      this.activityLogDialog = true;
    }
  }

  private checkPermissions(user: UserBaseModel | null): void {
    if (user) {
      // PermissionsHelper.hasRole('super_administrator', user).then((result: boolean) => {
      //   this.showSuperAdminButton = result;
      // });

      PermissionsHelper.hasRole('super_administrator', user).then((result: boolean) => {
        this.showActivityLogButton = result;

        if (!result) {
          PermissionsHelper.hasRole('administrator', user).then((result: boolean) => {
            this.showActivityLogButton = result;
          });
        }
      });
    }
  }

  /**
   * Searching
   */
  private search = '';

  private onQueryChange(query: string) {
    this.search = query;
  }

  /**
   * Schedule calendar dialog
   */

  private scheduleDate = '';
  private scheduleMinDate = moment().add(1, 'day').format('YYYY-MM-DD');
  private scheduleTime = '';
  private scheduleDialog = false;
  private scheduleDateDialog = false;
  private scheduleTimeDialog = false;

  /**
   * Data table
   */

  private stateLabelsColors: Dictionary<string> = {
    PUBLIC: colors.green.base,
    SCHEDULED: colors.orange.base,
    WAITING_FOR_CONCLUSION: colors.blue.darken4,
    CONCLUDED: colors.blue.accent2,
    ARCHIVE: colors.brown.lighten4,
    // ARCHIVE: colors.grey.lighten2,
    DRAFT: 'primary'
  };

  private page = 1;
  private itemsPerPage = 10;

  private itemStringValueMaxLength = 20;

  private selectedItem: DataTableOfferItem | null = null;
  private displayedHeadersExtraLarge = [
    'referenceNumber',
    'position',
    'type',
    'state',
    // 'publicationDate',
    // 'expirationDate',
    'updatedAt'
  ];
  private displayedHeadersLarge = ['referenceNumber', 'position', 'type', 'state'];
  private displayedHeadersMedium = ['referenceNumber', 'position', 'state'];

  get tableHeaders() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs':
        return this.displayedHeadersExtraLarge;
      case 'sm':
      case 'md':
        return this.displayedHeadersMedium;
      case 'lg':
        return this.displayedHeadersLarge;
      default:
        return this.displayedHeadersExtraLarge;
    }
  }

  get offerModels(): { [key: string]: string }[] {
    const parsedOffers: { [key: string]: string }[] = [];

    this.offers?.forEach((offer: Offer) => {
      const simpleOffer: { [key: string]: string } = {};

      // It must be always displayedHeadersExtraLarge instead of this.tableHeader,
      // so data-table-item has all necessary data inside!
      this.displayedHeadersExtraLarge.forEach((key: string) => {
        const offerVal = offer[key as keyof Offer];

        if (isNumber(offerVal)) {
          simpleOffer[key] = this.getLabelByPath(`offer.${key}.${offerVal}`) || '--';
        }

        if (isString(offerVal)) {
          if (
            [
              'createdAt',
              // 'updatedAt', // It is left commented, to better sort items in table view
              'expirationDate',
              'publicationDate',
              'documentsSubmissionDeadline'
            ].includes(key)
          ) {
            // eslint-disable-next-line prefer-destructuring
            simpleOffer[key] = moment(offerVal).format('YYYY-MM-DD HH:mm');
          } else {
            simpleOffer[key] =
              offerVal.length > this.itemStringValueMaxLength
                ? `${offerVal.substring(0, this.itemStringValueMaxLength)}`
                : offerVal;
          }
        }
      });

      if (offer.uuid) {
        simpleOffer.uuid = offer.uuid;
      }

      if (offer.state) {
        simpleOffer.stateIndex = offer.state.toString();
      }

      if (offer.type) {
        simpleOffer.typeIndex = offer.type.toString();
      }

      parsedOffers.push(simpleOffer);
    });

    return parsedOffers;
  }

  get headers() {
    const headers: TableHeader[] = [];

    this.displayedHeadersExtraLarge.forEach((key: string) => {
      headers.push({
        text: this.getLabelByPath(`offer.attributes.${key}`) || '',
        value: key,
        cellClass: this.tableHeaders.includes(key) ? '' : 'd-none',
        class: this.tableHeaders.includes(key) ? '' : 'd-none'
      });
    });

    if (this.isDesktop) {
      headers.push({
        text: this.$t('views.adminPanel.jobOffers.dataTable.headers.operations') as string,
        value: 'operations'
      });
    }

    // Adds expand icon at left side of table
    headers.push({ text: '', value: 'data-table-expand' });

    return headers;
  }

  private getOfferStateLabelColor(stateIndex: string, data: any): string {
    const key = this.getConstantByPath(`offer.state.${stateIndex}`);

    if (key) {
      // Published offer
      if (key === 'PUBLISH' && isString(data) && this.isScheduled(data)) {
        return this.stateLabelsColors.SCHEDULED;
      }

      return this.stateLabelsColors[key];
    }

    return colors.shades.white;
  }

  private isScheduled(publicationDate: string): boolean {
    return moment(publicationDate) > moment();
  }

  /**
   * Action buttons
   */

  // Allows to animate given action button, to indicate tha something is happening
  private actionsLoading: ActionsLoading = {
    publish: '',
    delete: '',
    archive: '',
    schedule: '',
    conclude: ''
  };

  /**
   * Actions dispatcher
   */

  private async actionsDispatcher(
    item: string | Offer,
    action: ProcessOfferActions,
    actionAccepted?: boolean
  ): Promise<void> {
    let offer: Offer | undefined;

    if (isString(item)) {
      offer = this.getOfferByUuid(item);
    } else {
      offer = item;
    }

    this.resetAfkTimer();

    if (offer) {
      switch (action) {
        case ProcessOfferActions.TEMPLATE:
          await this.actionNewOffer(offer);
          break;

        case ProcessOfferActions.PREVIEW:
          await this.actionPreview(offer);
          break;

        case ProcessOfferActions.PUBLISH:
          if (actionAccepted) {
            await this.actionPublish(offer);
            this.yesNoDialog = false;
            this.yesNoDialogLoading = false;
          } else {
            this.yesNoDialogLoading = false;
            this.yesNoDialogLoadingColor = this.stateLabelsColors.PUBLIC;
            this.yesNoDialogAction = action;
            this.yesNoDialogItemUuid = offer;
            this.yesNoDialogMessage = [
              this.$t(
                'views.adminPanel.jobOffers.components.yesNoDialog.actions.publish.message'
              ) as string,
              this.$t('views.adminPanel.jobOffers.components.yesNoDialog.cannotUndone') as string
            ];
            this.yesNoDialogTitle = this.$t(
              'views.adminPanel.jobOffers.components.yesNoDialog.actions.publish.title'
            ) as string;
            this.yesNoDialog = true;
          }
          break;
        case ProcessOfferActions.SCHEDULE:
          if (actionAccepted) {
            await this.actionSchedule(offer, this.scheduleDate, this.scheduleTime);
            this.scheduleDialog = false;
            this.yesNoDialogLoading = false;
          } else {
            this.yesNoDialogLoading = false;
            this.yesNoDialogLoadingColor = this.stateLabelsColors.SCHEDULED;
            this.yesNoDialogAction = action;
            this.yesNoDialogItemUuid = offer;
            this.scheduleTime = moment().format('HH:00');
            this.scheduleDate = moment().add(1, 'day').format('YYYY-MM-DD');
            this.scheduleDialog = true;
          }

          break;

        case ProcessOfferActions.DELETE:
          if (actionAccepted) {
            await this.actionDelete(offer);
            this.yesNoDialog = false;
            this.yesNoDialogLoading = false;
          } else {
            this.yesNoDialogAction = action;
            this.yesNoDialogItemUuid = offer;
            this.yesNoDialogTitle = this.$t(
              'views.adminPanel.jobOffers.components.yesNoDialog.actions.delete.title'
            ) as string;
            this.yesNoDialogMessage = this.$t(
              'views.adminPanel.jobOffers.components.yesNoDialog.actions.delete.message'
            ) as string;
            this.yesNoDialog = true;
          }
          break;

        case ProcessOfferActions.ARCHIVE:
          if (actionAccepted) {
            await this.actionArchive(offer);
            this.yesNoDialog = false;
          } else {
            this.yesNoDialogLoadingColor = this.stateLabelsColors.ARCHIVE;
            this.yesNoDialogAction = action;
            this.yesNoDialogItemUuid = offer;

            this.yesNoDialogMessage = [
              this.$t(
                'views.adminPanel.jobOffers.components.yesNoDialog.actions.archive.message'
              ) as string,
              this.$t('views.adminPanel.jobOffers.components.yesNoDialog.cannotUndone') as string
            ];

            this.yesNoDialogTitle = this.$t(
              'views.adminPanel.jobOffers.components.yesNoDialog.actions.archive.title'
            ) as string;
            this.yesNoDialog = true;
          }
          break;

        case ProcessOfferActions.EDIT:
          await this.actionEdit(offer);
          break;

        case ProcessOfferActions.CONCLUDE:
          this.offerToConclude = offer;
          this.competitionConclusionDialog = true;
          break;

        default:
          this.showErrorSnackbar();
          break;
      }
    } else {
      this.showErrorSnackbar();
    }
  }

  /**
   * Actions
   */

  private async actionPreview(offer: Offer): Promise<void> {
    await this.routesHelper.go(OfferRoutesNames.VIEW, { uuid: offer.uuid || '' });
  }

  private async actionPublish(offer: Offer): Promise<void> {
    this.actionsLoading.publish = offer.uuid || '';

    if (await this.publishOffer(offer)) {
      this.showSnackbar({
        message: this.$t(
          'views.adminPanel.jobOffers.snackbar.messages.success.published'
        ) as string,
        type: PwrSnackbarTypes.SUCCESS
      });
    } else if (!this.showStateValidationErrorIfAvailable()) {
      this.showErrorSnackbar();
    }

    this.selectedItem = {
      expirationDate: '',
      publicationDate: '',
      type: '',
      typeIndex: offer.type?.toString() ?? '',
      updatedAt: '',
      position: '',
      referenceNumber: '',
      state: '',
      stateIndex: offer.state?.toString() ?? '',
      uuid: offer.uuid ?? ''
    };

    this.actionsLoading.publish = '';
  }

  private async actionSchedule(
    offer: Offer,
    scheduleDate: string,
    scheduleTime: string
  ): Promise<void> {
    this.actionsLoading.schedule = offer.uuid || '';

    const date = `${scheduleDate} ${scheduleTime}:00`;

    if (await this.scheduleOffer({ offer, date })) {
      this.showSnackbar({
        message: this.$t(
          'views.adminPanel.jobOffers.snackbar.messages.success.scheduledPublication'
        ) as string,
        type: PwrSnackbarTypes.SUCCESS
      });
    } else if (!this.showStateValidationErrorIfAvailable()) {
      this.showErrorSnackbar();
    }

    this.selectedItem = {
      expirationDate: '',
      publicationDate: '',
      type: '',
      typeIndex: offer.type?.toString() ?? '',
      updatedAt: '',
      position: '',
      referenceNumber: '',
      state: '',
      stateIndex: offer.state?.toString() ?? '',
      uuid: offer.uuid ?? ''
    };

    this.actionsLoading.schedule = '';
  }

  private async actionDelete(offer: Offer): Promise<void> {
    this.actionsLoading.delete = offer.uuid || '';

    if (await this.deleteOffer(offer)) {
      this.showSnackbar({
        message: this.$t('views.adminPanel.jobOffers.snackbar.messages.success.deleted') as string,
        type: PwrSnackbarTypes.SUCCESS
      });
    } else {
      this.showErrorSnackbar();
    }

    this.selectedItem = null;
    this.actionsLoading.delete = '';
  }

  private async actionArchive(offer: Offer): Promise<void> {
    this.actionsLoading.archive = offer.uuid || '';

    if (await this.archiveOffer(offer)) {
      this.showSnackbar({
        message: this.$t('views.adminPanel.jobOffers.snackbar.messages.success.archived') as string,
        type: PwrSnackbarTypes.SUCCESS
      });
    } else if (!this.showStateValidationErrorIfAvailable()) {
      this.showErrorSnackbar();
    }

    this.selectedItem = {
      expirationDate: '',
      publicationDate: '',
      type: '',
      typeIndex: offer.type?.toString() ?? '',
      updatedAt: '',
      position: '',
      referenceNumber: '',
      state: '',
      stateIndex: offer.state?.toString() ?? '',
      uuid: offer.uuid ?? ''
    };
    this.actionsLoading.archive = '';
  }

  private async actionEdit(offer: Offer): Promise<void> {
    await this.routesHelper.go(OfferRoutesNames.EDIT, { uuid: offer.uuid || '' });
  }

  private newOfferDialog = false;
  private newOfferDialogTemplateUuid: string | null = null;

  private actionNewOffer(template?: Offer): void {
    if (template) {
      this.newOfferDialogTemplateUuid = template.uuid || null;
    } else {
      this.newOfferDialogTemplateUuid = null;
    }

    this.newOfferDialog = true;
  }

  private getOfferByUuid(uuid: string): Offer | undefined {
    const result: Offer[] = this.offers.filter((offer: Offer) => offer.uuid === uuid);
    if (result) {
      return result[0];
    }
    return undefined;
  }

  private onRowClick(item: DataTableOfferItem, event: any) {
    if (!event.isSelected) {
      event.select(true);
    }
  }

  private onItemSelect(event: any) {
    this.resetAfkTimer();
    if (!this.isScrollUpFabEnabled) {
      if (event.value) {
        const { item }: { item: DataTableOfferItem } = event;
        this.selectedItem = item;
      } else {
        this.selectedItem = null;
      }
    }
  }

  /**
   * Floating actions button
   */

  private showFloatingActions = false;

  get isSpeedDialLoading(): boolean {
    return Object.keys(this.actionsLoading).some(
      (key) => this.actionsLoading[key as keyof ActionsLoading].length
    );
  }

  /**
   * State Validator Dialog
   */

  private sVDialog = false;
  private sVResponse: OfferResponseData = {
    message: '',
    errors: {}
  };

  private onSVDialogClose(): void {
    this.sVDialog = false;
    this.actionsLoading = {
      publish: '',
      delete: '',
      archive: '',
      schedule: '',
      conclude: ''
    };

    this.clearLastActionResponse();
  }

  get sVDialogMessage(): string {
    return isString(this.lastActionResponseData)
      ? this.lastActionResponseData
      : (this.$t('views.adminPanel.jobOffers.snackbar.stateValidation.message') as string);
  }

  private showStateValidationErrorIfAvailable(): boolean {
    if (this.lastActionResponseCode === 422) {
      this.sVDialog = true;
      return true;
    }

    return false;
  }

  /**
   * Yes / No dialog
   */

  private yesNoDialog = false;
  private yesNoDialogMessage: string | string[] = '';
  private yesNoDialogTitle = '';
  private yesNoDialogLoading = false;
  private yesNoDialogLoadingColor = 'black';

  private yesNoDialogAction = '';
  private yesNoDialogItemUuid?: Offer;

  private onYesNoDialogYes(): void {
    this.yesNoDialogLoading = true;

    if (this.yesNoDialogItemUuid) {
      this.actionsDispatcher(
        this.yesNoDialogItemUuid,
        this.yesNoDialogAction as ProcessOfferActions,
        true
      );
    } else {
      this.yesNoDialog = false;
    }
  }

  private onYesNoDialogNo(): void {
    this.yesNoDialogLoading = false;
    this.yesNoDialogMessage = '';
    this.yesNoDialogTitle = '';
    this.yesNoDialogAction = '';
    this.yesNoDialogItemUuid = undefined;
  }

  /**
   * Conclude competition dialog
   */
  private competitionConclusionDialog: boolean = false;
  private offerToConclude: Offer | null = null;
}

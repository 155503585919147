var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('pwr-yes-no-dialog',{attrs:{"title":_vm.$t('views.adminPanel.jobOffers.components.competitionConclusionDialog.title'),"persistent":"","yes-button-text":_vm.yesText,"disable-yse-button":!(_vm.fileUploadDone && _vm.offer.expirationDate && _vm.file),"loading":_vm.loading},on:{"yes":_vm.concludeOffer},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('div',{staticClass:"pwr-my"},[_c('p',{staticClass:"text-center"},[_vm._v(" Wgraj dokument rozstrzygający konkurs. "),_c('br'),_vm._v(" Oferta może mieć tylko 1 taki plik."),_c('br'),_vm._v(" Ponowne wgranie pliku zastąpi poprzedni. ")]),_c('pwr-file-uploader',{attrs:{"allow-multiple":false,"accepted-mime-types":[
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/pdf'
      ]},on:{"state":_vm.onFileStateChange,"file-deleted":_vm.onFileDeleted}}),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({class:{ 'required': !_vm.offer.isContest },attrs:{"label":_vm.getLabelByPath('offer.attributes.expirationDate'),"prepend-icon":"$calendarAlt","readonly":"","rules":_vm.rules.expirationDate},model:{value:(_vm.offer.expirationDate),callback:function ($$v) {_vm.$set(_vm.offer, "expirationDate", $$v)},expression:"offer.expirationDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuExpirationDate),callback:function ($$v) {_vm.menuExpirationDate=$$v},expression:"menuExpirationDate"}},[(_vm.offer)?_c('v-date-picker',{attrs:{"min":_vm.dateScopes.expirationDate.min()},on:{"input":() => {
            _vm.menuExpirationDate = false;
          }},model:{value:(_vm.offer.expirationDate),callback:function ($$v) {_vm.$set(_vm.offer, "expirationDate", $$v)},expression:"offer.expirationDate"}}):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
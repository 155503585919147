
import { Component, Prop } from 'vue-property-decorator';
import { ActivityLog } from '@/types';
import PwrVue from '@/components/PwrVue';
import OpUsernameWithAvatar from '@/components/Op/User/OpUsernameWithAvatar.vue';

@Component({
  components: { OpUsernameWithAvatar }
})
export default class OpEventsHistoryTable extends PwrVue {
  @Prop({ default: () => [] }) activityLogs!: ActivityLog[];
  @Prop() loading!: boolean;

  private headers = [
    {
      text: this.$t('views.adminPanel.jobOffers.components.eventsLog.headers.user'),
      align: 'start',
      value: 'user'
    },
    {
      text: this.$t('views.adminPanel.jobOffers.components.eventsLog.headers.event'),
      value: 'log'
    },
    {
      text: this.$t('views.adminPanel.jobOffers.components.eventsLog.headers.date'),
      align: 'start',
      value: 'log-createdAt',
      width: '155'
    }
  ];
}

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:_vm.isProp(_vm.fab) ? '' : 'd-flex flex-column flex-md-row justify-end'},[(
      (_vm.getConstantByPath('offer.state.' + _vm.item.stateIndex) === 'PUBLIC' &&
        _vm.getConstantByPath('offer.type.' + _vm.item.typeIndex) !== 'TEACHER_FROM_CONTEST') ||
      (_vm.getConstantByPath('offer.state.' + _vm.item.stateIndex) === 'CONCLUDED' &&
        _vm.getConstantByPath('offer.type.' + _vm.item.typeIndex) !== 'TEACHER_FROM_CONTEST')
    )?_c('admin-panel-action-item',{attrs:{"icon":"$archive","action":"archive","tooltip":_vm.$t('views.adminPanel.jobOffers.components.actionItemsGroup.tooltips.archive'),"uuid":_vm.item.uuid,"loading":_vm.actionsLoading.archive === _vm.item.uuid,"color":_vm.getColor('', 'primary'),"fab":_vm.isProp(_vm.fab)},on:{"click":function($event){return _vm.$emit('action', _vm.item.uuid, 'ARCHIVE')}}}):_vm._e(),(
      _vm.getConstantByPath('offer.state.' + _vm.item.stateIndex) === 'WAITING_FOR_CONCLUSION' ||
      _vm.getConstantByPath('offer.state.' + _vm.item.stateIndex) === 'CONCLUDED'
    )?_c('admin-panel-action-item',{attrs:{"icon":"$fileAlt","action":"addResult","tooltip":_vm.addResultTooltip,"uuid":_vm.item.uuid,"loading":_vm.actionsLoading.conclude === _vm.item.uuid,"color":_vm.getColor('WAITING_FOR_CONCLUSION', 'gray'),"fab":_vm.isProp(_vm.fab)},on:{"click":function($event){return _vm.$emit('action', _vm.item.uuid, 'CONCLUDE')}}}):_vm._e(),(
      _vm.getConstantByPath('offer.state.' + _vm.item.stateIndex) === 'DRAFT' ||
      _vm.getConstantByPath('offer.state.' + _vm.item.stateIndex) === 'SCHEDULED'
    )?_c('admin-panel-action-item',{attrs:{"icon":"$globe","action":"publish","tooltip":_vm.$t('views.adminPanel.jobOffers.components.actionItemsGroup.tooltips.publish'),"uuid":_vm.item.uuid,"loading":_vm.actionsLoading.publish === _vm.item.uuid,"color":_vm.getColor('PUBLISH', 'green'),"fab":_vm.isProp(_vm.fab)},on:{"click":function($event){return _vm.$emit('action', _vm.item.uuid, 'PUBLISH')}}}):_vm._e(),(_vm.getConstantByPath('offer.state.' + _vm.item.stateIndex) === 'DRAFT')?_c('admin-panel-action-item',{attrs:{"icon":"$clock","action":"schedule","tooltip":_vm.$t('views.adminPanel.jobOffers.components.actionItemsGroup.tooltips.scheduledPublication'),"uuid":_vm.item.uuid,"loading":_vm.actionsLoading.schedule === _vm.item.uuid,"color":_vm.getColor('SCHEDULE', 'orange'),"fab":_vm.isProp(_vm.fab)},on:{"click":function($event){return _vm.$emit('action', _vm.item.uuid, 'SCHEDULE')}}}):_vm._e(),(
      _vm.getConstantByPath('offer.state.' + _vm.item.stateIndex) !== 'ARCHIVE' &&
      _vm.getConstantByPath('offer.state.' + _vm.item.stateIndex) !== 'WAITING_FOR_CONCLUSION' &&
      _vm.getConstantByPath('offer.state.' + _vm.item.stateIndex) !== 'CONCLUDED'
    )?_c('admin-panel-action-item',{attrs:{"icon":"$edit","action":"edit","tooltip":_vm.$t('views.adminPanel.jobOffers.components.actionItemsGroup.tooltips.edit'),"uuid":_vm.item.uuid,"color":_vm.getColor('EDIT', 'blue'),"fab":_vm.isProp(_vm.fab)},on:{"click":function($event){return _vm.$emit('action', _vm.item.uuid, 'EDIT')}}}):_vm._e(),(_vm.getConstantByPath('offer.state.' + _vm.item.stateIndex) === 'DRAFT')?_c('admin-panel-action-item',{attrs:{"icon":"$trash","action":"delete","tooltip":_vm.$t('views.adminPanel.jobOffers.components.actionItemsGroup.tooltips.delete'),"uuid":_vm.item.uuid,"loading":_vm.actionsLoading.delete === _vm.item.uuid,"color":_vm.getColor('DELETE', 'red'),"fab":_vm.isProp(_vm.fab)},on:{"click":function($event){return _vm.$emit('action', _vm.item.uuid, 'DELETE')}}}):_vm._e(),_c('admin-panel-action-item',{attrs:{"icon":"$copy","action":"template","tooltip":_vm.$t('views.adminPanel.jobOffers.components.actionItemsGroup.tooltips.template'),"uuid":_vm.item.uuid,"color":_vm.getColor('TEMPLATE', 'black'),"fab":_vm.isProp(_vm.fab)},on:{"click":function($event){return _vm.$emit('action', _vm.item.uuid, 'TEMPLATE')}}}),_c('admin-panel-action-item',{attrs:{"icon":"$externalLinkAlt","action":"preview","tooltip":_vm.$t('views.adminPanel.jobOffers.components.actionItemsGroup.tooltips.preview'),"uuid":_vm.item.uuid,"color":_vm.getColor('PREVIEW', 'black'),"fab":_vm.isProp(_vm.fab)},on:{"click":function($event){return _vm.$emit('action', _vm.item.uuid, 'PREVIEW')}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }

import { Component, Prop } from 'vue-property-decorator';
import PwrVue from '@/components/PwrVue';

@Component
export default class OpUsernameWithAvatar extends PwrVue {
  @Prop({ default: () => '' }) username!: string;
  @Prop({ default: () => '' }) name!: string;
  @Prop({ default: () => '' }) surname!: string;
}


import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component
export default class OpEventsHistoryCard extends Vue {
  @Prop({ default: () => '' }) title!: string;

  @Emit()
  private close() {
    return true;
  }
}


import { Component, Prop, VModel } from 'vue-property-decorator';
import { FilePondFile } from 'filepond';
import { Action } from 'vuex-class';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { CoreInputValidators } from '@/modules/core/core-input-validators';
import { ActionTypes as OffersActionTypes } from '@/store/offers/types';
import { PwrSnackbarTypes } from '@/components/Pwr/PwrSnackbar/types';

import Offer from '@/models/Offer';
import { FilesQueue, UploadState } from '@/components/Pwr/PwrFiles/lang/types';
import PwrVue from '@/components/PwrVue';
import OffersService from '@/services/offers/OffersService';
import PwrYesNoDialog from '@/components/Pwr/Dialogs/PwrYesNoDialog.vue';
import PwrFileUploader from '@/components/Pwr/PwrFiles/PwrFileUploader.vue';
import PwrCard from '@/components/Pwr/PwrCard/PwrCard.vue';

const offersNamespace = 'offers';

@Component({
  components: { PwrCard, PwrFileUploader, PwrYesNoDialog }
})
export default class CompetitionConclusionDialog extends PwrVue {
  @VModel({ default: () => true }) visible!: boolean;
  @Prop() offer!: Offer;

  @Action(OffersActionTypes.CONCLUDE_OFFER, { namespace: offersNamespace }) concludeOfferAction!: (
    offer: Offer
  ) => Promise<boolean>;

  private fileUploadDone = false;
  private file: FilePondFile | null = null;
  private loading = false;

  private menuExpirationDate: boolean = false;

  private rules = {
    expirationDate: [
      (v: string) => {
        if (!isEmpty(v)) {
          return CoreInputValidators.minDate(
            moment(v).startOf('day'),
            moment().startOf('day'),
            false,
            this.$t('date.today') as string
          );
        }
        return true;
      }
    ]
  };

  private dateScopes = {
    expirationDate: {
      min: () =>
        moment(moment())
          .add(this.type() === 'TEACHER_FROM_CONTEST' ? 30 : 0, 'day')
          .format('YYYY-MM-DD')
    }
  };

  private getPublicationDate(): string | undefined {
    return this.offer.publicationDate;
  }

  private type(): string {
    return this.getConstantByPath(`offer.type.${this.offer.type}`) ?? '';
  }

  get yesText(): string {
    if (this.offer && this.getConstantByPath(`offer.state.${this.offer.state}`) === 'CONCLUDED') {
      return this.$t(
        'views.adminPanel.jobOffers.components.competitionConclusionDialog.buttons.yes2'
      ) as string;
    }

    return this.$t(
      'views.adminPanel.jobOffers.components.competitionConclusionDialog.buttons.yes'
    ) as string;
  }

  private onFileStateChange(state: UploadState, files: FilePondFile[]): void {
    if (state === UploadState.COMPLETE) {
      this.fileUploadDone = true;
      // eslint-disable-next-line prefer-destructuring
      this.file = files[0];
    } else {
      this.fileUploadDone = false;
    }
  }

  private clearConclusion(): void {
    this.fileUploadDone = false;
    this.file = null;
    this.loading = false;
  }

  private async concludeOffer(): Promise<void> {
    this.loading = true;

    if (!this.file) {
      this.showErrorSnackbar(
        this.$t(
          'views.adminPanel.jobOffers.components.competitionConclusionDialog.errors.fileUpload'
        ) as string
      );

      this.clearConclusion();
      return;
    }

    if (!this.offer) {
      this.showErrorSnackbar(
        this.$t(
          'views.adminPanel.jobOffers.components.competitionConclusionDialog.success.offerRef'
        ) as string
      );

      this.clearConclusion();
      return;
    }

    const filesQueue: FilesQueue = {
      destination: 'resolution',
      // @ts-ignore
      serverIds: [this.file.serverId]
    };

    const fileAttachResult = await OffersService.attach(this.offer, filesQueue);

    if (!fileAttachResult) {
      this.showErrorSnackbar(
        this.$t(
          'views.adminPanel.jobOffers.components.competitionConclusionDialog.errors.fileAttach'
        ) as string
      );

      this.clearConclusion();
      return;
    }

    if (this.getConstantByPath(`offer.state.${this.offer.state}`) !== 'CONCLUDED') {
      const conclusionResult = await this.concludeOfferAction(this.offer);

      if (!conclusionResult) {
        this.showErrorSnackbar(
          this.$t(
            'views.adminPanel.jobOffers.components.competitionConclusionDialog.errors.offer'
          ) as string
        );

        this.clearConclusion();
        return;
      }
    }

    this.showSnackbar({
      message:
        this.getConstantByPath(`offer.state.${this.offer.state}`) === 'CONCLUDED'
          ? (this.$t(
              'views.adminPanel.jobOffers.components.competitionConclusionDialog.success.offer2'
            ) as string)
          : (this.$t(
              'views.adminPanel.jobOffers.components.competitionConclusionDialog.success.offer'
            ) as string),
      type: PwrSnackbarTypes.SUCCESS
    });

    this.clearConclusion();

    this.loading = false;
    this.visible = false;
    this.$emit('concluded');
  }

  private onFileDeleted(): void {
    this.fileUploadDone = false;
    this.file = null;
  }
}

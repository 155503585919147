
import { Component, Prop } from 'vue-property-decorator';
import PwrVue from '@/components/PwrVue';
import Offer, { dateTimeFields } from '@/models/Offer';

@Component
export default class OpMoreInfo extends PwrVue {
  @Prop() offer!: Offer;

  get dateFields(): (keyof Offer)[] {
    return dateTimeFields;
  }

  private hasData(): boolean {
    for (let i = 0; i < this.dateFields.length; i++) {
      const field = this.dateFields[i];

      if (this.offer[field]) {
        return true;
      }
    }

    return false;
  }
}

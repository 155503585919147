
import {Component} from 'vue-property-decorator';
import moment from 'moment';
import PwrYesNoDialog from '@/components/Pwr/Dialogs/PwrYesNoDialog.vue';
import PwrCard from '@/components/Pwr/PwrCard/PwrCard.vue';
import PwrCardTitle from '@/components/Pwr/PwrCard/PwrCardTitle.vue';
import PwrBtn from '@/components/Pwr/Buttons/PwrBtn/PwrBtn.vue';

@Component({
  components: { PwrYesNoDialog, PwrCardTitle, PwrCard, PwrBtn }
})
export default class OpScheduleConfirmation extends PwrYesNoDialog {
  private scheduleDate = moment().format('YYYY-MM-DD');
  private scheduleMinDate = moment().format('YYYY-MM-DD');
  private scheduleTime = moment().add(1, 'hour').format('HH:00');
  private scheduleDateDialog = false;
  private scheduleTimeDialog = false;

  private closePicker(value:string) {
    this.scheduleTime = `${value}:00`;
    this.scheduleTimeDialog = false
  }
}

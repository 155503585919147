
import { Component, Prop } from 'vue-property-decorator';
import { Dictionary } from '@/types';
import PwrVue from '@/components/PwrVue';
import PwrBtn from '@/components/Pwr/Buttons/PwrBtn/PwrBtn.vue';
import { ActionsLoading, DataTableOfferItem } from '../types';
import ActionItem from './ActionItem.vue';

@Component({
  components: { PwrBtn, AdminPanelActionItem: ActionItem }
})
export default class ActionItemGroup extends PwrVue {
  @Prop() fab!: boolean | string;
  @Prop({ required: true }) item!: DataTableOfferItem;
  @Prop({ required: true }) actionsLoading!: ActionsLoading;
  @Prop() colors!: Dictionary<string>;

  private getColor(label: string, defColor: string): string {
    if (Object.keys(this.colors).includes(label)) {
      return this.colors[label];
    }

    return defColor;
  }

  get addResultTooltip(): string {
    return this.getConstantByPath(`offer.state.${this.item.stateIndex}`) === 'CONCLUDED'
      ? (this.$t(
          'views.adminPanel.jobOffers.components.actionItemsGroup.tooltips.concludeAgain'
        ) as string)
      : (this.$t(
          'views.adminPanel.jobOffers.components.actionItemsGroup.tooltips.conclude'
        ) as string);
  }
}

export interface TableHeader {
  text: string;
  value: string;
  cellClass?: string;
  class?: string;
}

export enum ProcessOfferActions {
  PUBLISH = 'PUBLISH',
  SCHEDULE = 'SCHEDULE',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  PREVIEW = 'PREVIEW',
  TEMPLATE = 'TEMPLATE',
  ARCHIVE = 'ARCHIVE',
  CONCLUDE = 'CONCLUDE'
}

export interface DataTableOfferItem {
  position: string;
  referenceNumber: string;
  state: string;
  stateIndex: string;
  uuid: string;
  type: string;
  typeIndex: string;
  publicationDate: string;
  updatedAt: string;
  expirationDate: string;
}

export interface ActionsLoading {
  publish: string;
  delete: string;
  archive: string;
  schedule: string;
  conclude: string;
}

export interface RichTextField {
  prop: string;
  default?: string;
  rules: { (input: any): boolean | string }[];
  required?: boolean;
  label?: string;
  errorMessages: string[];
}


import { Component, Prop, VModel, Watch } from 'vue-property-decorator';
import { debounce } from 'lodash';
import { Action } from 'vuex-class';
import { AxiosError, AxiosResponse } from 'axios';
import { CoreInputValidators } from '@/modules/core/core-input-validators';
import { Dictionary } from '@/types';
import { ActionTypes as OffersActionTypes, TemplateOfferPayload } from '@/store/offers/types';
import Offer from '@/models/Offer';
import PwrVue from '@/components/PwrVue';
import { RoutesNames as OfferRouteNames } from '@/router/offers/types';
import PwrCard from '@/components/Pwr/PwrCard/PwrCard.vue';
import PwrCardTitle from '@/components/Pwr/PwrCard/PwrCardTitle.vue';
import PwrBtn from '@/components/Pwr/Buttons/PwrBtn/PwrBtn.vue';
import PwrYesNoDialog from '@/components/Pwr/Dialogs/PwrYesNoDialog.vue';

const offersNamespace = 'offers';

@Component({
  components: { PwrYesNoDialog, PwrBtn, PwrCardTitle, PwrCard }
})
export default class NewOfferDialog extends PwrVue {
  @VModel() visible!: boolean;
  @Prop() templateUuid!: string | null;

  @Action(OffersActionTypes.CREATE_OFFER, { namespace: offersNamespace }) createOfferAction!: (
    offer: Offer
  ) => Promise<Offer | undefined>;

  @Action(OffersActionTypes.CREATE_OFFER_FROM_TEMPLATE, { namespace: offersNamespace })
  createOfferFromTemplateAction!: (payload: TemplateOfferPayload) => Promise<Offer | undefined>;

  private errors: Dictionary<string[]> = {};
  private isValid = false;

  private debounceResetAfkTimer = debounce(this.resetAfkTimer, 5000);

  private offer: Offer = {
    type: undefined,
    referenceNumber: undefined,
    position: undefined,
    unit: undefined
  };

  @Watch('visible', { deep: true })
  private onVisibleChange(): void {
    this.offer = {
      type: undefined,
      referenceNumber: undefined,
      position: undefined,
      unit: undefined
    };
  }

  get types() {
    return this.getItems('type', 'offers-type');
  }

  get units() {
    return this.getItems('unit', 'units');
  }

  private onInputField(item: string): void {
    if (this.errors[item]) {
      delete this.errors[item];
    }

    this.debounceResetAfkTimer();
  }

  // Rules
  private ruleType = [
    (v: any) => CoreInputValidators.required(v, this.getLabelByPath('offer.attributes.type'))
  ];

  private ruleReferenceNumber = [
    (v: any) =>
      CoreInputValidators.required(v, this.getLabelByPath('offer.attributes.referenceNumber')),

    (v: any) =>
      CoreInputValidators.minRule(
        v,
        3,
        true,
        this.getLabelByPath('offer.attributes.referenceNumber')
      ),

    (v: any) =>
      CoreInputValidators.maxRule(
        v,
        100,
        true,
        this.getLabelByPath('offer.attributes.referenceNumber') as string
      )
  ];

  private rulePosition = [
    (v: any) => CoreInputValidators.required(v, this.getLabelByPath('offer.attributes.position')),
    (v: any) =>
      CoreInputValidators.minRule(
        v,
        5,
        true,
        this.getLabelByPath('offer.attributes.position') as string
      ),
    (v: any) =>
      CoreInputValidators.maxRule(
        v,
        100,
        true,
        this.getLabelByPath('offer.attributes.position') as string
      )
  ];

  private ruleUnit = [
    (v: any) => CoreInputValidators.required(v, this.getLabelByPath('offer.attributes.unit'))
  ];

  get canContinue(): boolean {
    return this.isValid;
  }

  // Offer actions
  private async createNewOffer(): Promise<void> {
    let offer: Offer | undefined;

    try {
      if (this.templateUuid) {
        offer = await this.createOfferFromTemplateAction({
          templateOfferUuid: this.templateUuid || '',
          referenceNumber: this.offer.referenceNumber || ''
        });
      } else {
        offer = await this.createOfferAction(this.offer);
      }
    } catch (e: unknown) {
      const axiosError: AxiosResponse<any> | undefined = (e as AxiosError).response;

      this.showErrorSnackbar(axiosError?.data.message);
      if (axiosError?.data.errors) {
        this.errors = axiosError?.data.errors;
      }
    } finally {
      if (offer) {
        await this.routesHelper.go(OfferRouteNames.EDIT, { uuid: offer.uuid || '' });
      }
    }
  }

  private title(): string {
    return this.templateUuid
      ? (this.$t(
          'views.adminPanel.jobOffers.components.newOfferDialog.titleFromTemplate'
        ) as string)
      : (this.$t('views.adminPanel.jobOffers.components.newOfferDialog.title') as string);
  }
}

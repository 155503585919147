
import { Component, Prop, Ref } from 'vue-property-decorator';
import VueFilePond, { VueFilePondComponent } from 'vue-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import { FilePondFile, FilePondInitialFile } from 'filepond';
import { ProcessEnvBaseModel } from '@/modules/core/core-types';
import api from '@/modules/core/core-api';
import { ErrorHelper } from '@/modules/core/core-helpers';
import PwrVue from '@/components/PwrVue';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { Dictionary } from '@/types';
import { UploadState } from '@/components/Pwr/PwrFiles/lang/types';

// Create component
const FilePondComponent = VueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize
);

// Bardzo przydatne:
// @see https://pqina.nl/filepond/docs/api/instance/properties/

@Component({
  components: { FilePondComponent }
})
export default class PwrFileUploader extends PwrVue {
  @Ref('pond') filepond!: VueFilePondComponent;

  @Prop() initialFiles!: FilePondInitialFile[];
  @Prop({ default: () => true }) allowMultiple!: string | boolean;
  @Prop({ default: () => '50MB' }) maxFileSize!: string;
  @Prop({
    default: () => [
      'image/png',
      'image/jpg',
      'image/jpeg',
      'image/gif',
      'image/bmp',
      'image/svg+xml',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/pdf',
      'text/plain',
      'application/vnd.oasis.opendocument.text',
      'application/rtf'
    ]
  })
  acceptedMimeTypes!: string[];

  @Prop({
    default: () => ({
      'image/png': '.png',
      'image/jpg': '.jpg',
      'image/jpeg': '.jpeg',
      'image/gif': '.gif',
      'image/bmp': '.bmp',
      'image/svg+xml': '.svg',
      'application/pdf': '.pdf',
      'application/msword': '.doc',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx',
      'text/plain': '.txt',
      'application/vnd.oasis.opendocument.text': '.odt',
      'application/rtf': '.rtf'
    })
  })
  mimeTypesLabels!: Dictionary<string>;

  private uploadedFiles: FilePondFile[] = [];

  get serverUrl() {
    return `${(process.env as unknown as ProcessEnvBaseModel).VUE_APP_API_URL}/process`;
  }

  private processingComplete(): void {
    this.$emit('state', UploadState.COMPLETE, this.uploadedFiles);
  }

  private processing(): void {
    this.$emit('state', UploadState.PROCESSING, []);
  }

  private server = {
    url: this.serverUrl,
    process: '',
    revert: null,
    restore: null,
    load: null,
    fetch: null,
    remove: async (source: never, load: () => void, error: (errorText: string) => void) => {
      try {
        await api.delete(`files/${source}`);
        this.$emit('fileDeleted', source);
        load();
      } catch (e: unknown) {
        const axiosError = ErrorHelper.getAxiosError(e);

        if (axiosError) {
          error(axiosError.data.message);
        }

        error(e as string);
      }
    }
  };

  private onFileRemoved(): void {
    this.$emit('file-deleted');
  }

  private onUpdateFiles(): void {
    this.$emit('state', UploadState.IDLE, this.uploadedFiles);
  }
}

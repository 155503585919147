
import { Component, Prop } from 'vue-property-decorator';
import PwrVue from '@/components/PwrVue';
import PwrBtn from '@/components/Pwr/Buttons/PwrBtn/PwrBtn.vue';

@Component({
  components: { PwrBtn }
})
export default class ActionItem extends PwrVue {
  @Prop() fab!: boolean | string;
  @Prop({ required: true }) action!: string;
  @Prop({ required: true }) tooltip!: string;
  @Prop({ required: true }) uuid!: string;
  @Prop() loading!: boolean;
  @Prop({ required: true }) icon!: string;
  @Prop() color!: string;
}

export enum UploadState {
  IDLE,
  PROCESSING,
  COMPLETE
}

export interface FilesQueue {
  destination: string;
  serverIds: string[];
}
